<script setup lang="ts">
import type { Game, GameLastWinner } from "@/types";

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	type?: "slider_vertical" | "slider_horizontal";
	winnerData?: GameLastWinner;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const isGuest = useIsGuest();
const { t } = useT();
const route = useRoute();
const { open } = useFunrizeModals();
const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
const { isSweepStakes } = useSwitchMode();
const defaultBg = "/nuxt-img/card-games/default-bg.png";
const isGameFavorite = computed(() => props.game.isFavorite);

const handleToggleToFavorite = () => {
	handleToggleToFavoriteClick({ ...props.game, isFavorite: isGameFavorite.value });
};
const exclusiveLock = computed(() => props.game.isExclusive && !isSweepStakes.value);

const handleOpenGameClick = () => {
	if (props.game.isOnlyForApp) {
		open("LazyOModalGetAppMain");
		return;
	}

	if (exclusiveLock.value) {
		open("LazyOModalGamePreviewExclusive", {
			gameId: props.game.id,
			winnerData: props.winnerData
		});
		return;
	}

	if (props.game.gamePlayInfo) {
		open("LazyOModalGamePreview", {
			game: props.game,
			winnerData: props.winnerData
		});
		dispatchGAEvent({
			event: "click_button",
			location: props.game.slug ?? "",
			button_name: "gameplay",
			form_name: (route.params.pageName as string) ?? ""
		});
		return;
	}

	handleOpenGame(props.game.slug, "gameplay");
};
</script>

<template>
	<div>
		<MGameCardHorizontal
			v-if="type === 'slider_horizontal'"
			:game="game"
			:gameImgHorizontal="`${baseImageUrl}${props.game?.logo2x1 || props.game?.logo}`"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="284"
			:height="174"
			:buttonName="t('Play')"
			:isFavorite="isGameFavorite"
			showGameTitle
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameActivityFeedBadge v-if="!isGuest" :winner="winnerData" />
				<MGameBadges :game="game" />
			</template>
		</MGameCardHorizontal>
		<MGameCard
			v-else-if="type === 'slider_vertical'"
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="160"
			:height="283"
			:isFavorite="isGameFavorite"
			showGameTitle
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameActivityFeedBadge v-if="!isGuest" :winner="winnerData" />
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
		<MGameCard
			v-else
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo || props.game?.img}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="160"
			:height="200"
			:isFavorite="isGameFavorite"
			showGameTitle
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameActivityFeedBadge v-if="!isGuest" :winner="winnerData" />
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	background: var(--cambe);
	border-radius: 8px;
}
:deep(.game-card) {
	.game-overlay {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.image-block {
		border-radius: 8px;
		background: var(--cambe);
	}
	.game-overlay {
		background-color: var(--cambe);
		.nuxt-icon {
			font-size: 56px;
		}
	}
	.top-info {
		top: 0;
		left: -2px;
		z-index: 3;
	}
	.game-title {
		.title {
			width: calc(100% - 24px);
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			bottom: 12px;
			left: 50%;
			transform: translate(-50%, 0);
			gap: 4px;
			z-index: 1;
			letter-spacing: 0.04em;
			line-height: 1;
			text-transform: uppercase;
			text-align: center;
			font-weight: 700;
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			white-space: wrap;

			& > span {
				font-size: 10px;
				font-weight: 400;
				text-shadow: none;
			}
		}
	}
	.btn-favorite {
		display: none;
		width: 32px;
		height: 28px;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		padding: var(--a-button-toolbar-padding);
		background: var(--a-button-toolbar-background);
		border: 0;
		border-radius: var(--a-button-toolbar-border-radius);
		border: var(--a-button-toolbar-border);
		cursor: pointer;
		box-shadow: var(--a-button-toolbar-box-shadow);
		transition: opacity 0.3s;
		&.active {
			background: var(--a-button-toolbar-background-active);
			border: var(--a-button-toolbar-border-active);
		}
	}

	&:hover {
		.btn-favorite {
			display: flex;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		@include media-breakpoint-down(lg) {
			img {
				transform: none;
			}
		}
	}
}
</style>
